import client from './client';

export default {
  agreeToNotice: data => {
    return client.post('seller/agree-to-notice', data).then(res => res.data);
  },
  getSellerBrands: data => {
    return client.get('seller/allbrands', data).then(res => res.data);
  },
  activateBrands: data => {
    return client.post('seller/brands/activate', data).then(res => res.data);
  },
}