/*
 * @Author: Gang Jiang 
 * @Date: 2024-09-14 15:23:45 
 * @Last Modified by: Gang Jiang
 * @Last Modified time: 2024-10-08 11:57:55
 * copy from web_h5_nuxt
 */
import axios from "axios";
import { apiDomain } from "../../config";
import { message } from 'ant-design-vue'

/**
 * 添加请求拦截器
 */

let devPrefix = apiDomain;
if (location.origin.match('st1')) {
  devPrefix = 'https://api.st1.artemisads.com';
}

axios.interceptors.request.use(
  config => {
    config.url = `https://${devPrefix}/api/artemisads/v1/${config.url}`;

    return config;
  },
  error => {
    //请求错误时做些事
    return Promise.reject(error);
  }
);

export { default as signApi } from './sign';
export { default as profileApi } from './profile';
export { default as adminApi } from './admin';
